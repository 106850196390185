<template>
  <div>
    <b-card no-body>
      <b-card-body class="p-0 m-0" style="padding: 0px; margin: 0px">
        <div>
          <div>
            <div>
              <b-row class="d-flex justify-content-between">
                <b-col cols="auto">
                  <h4>
                    Liste des projets <span>( {{ projects.length }} )</span>
                  </h4>
                </b-col>
                <b-col cols="auto">
                  <b-row>
                    <b-col>
                      <ProjectRequest />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <hr />
            </div>
            <div>
              <b-form-group label-cols-md="4" label-for="filterInput">
                <b-form-input
                  id="filterInput"
                  v-model="filterTable"
                  type="search"
                  placeholder="Rechercher..."
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </b-card-body>
      <!-- Table beginning -->
      <b-form-group>
        <b-form-radio-group
          v-model="choice"
          :options="options"
        ></b-form-radio-group>
      </b-form-group>
      <b-table
        style="height: 480px"
        no-border-collapse
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="projects"
        :fields="projectsItems"
        show-empty
        empty-text="Pas de projets disponible"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #cell(action)="data">
          <b-row>
            <b-col v-if="data.item.state == 0">
              <b-avatar
                variant="danger"
                button
                @click="
                  (showDeletedConfModal = true), (project_id = data.item.id)
                "
              >
                <feather-icon icon="TrashIcon" size="20" />
              </b-avatar>
            </b-col>
            <b-col v-if="data.item.state != 0">
              <b-avatar
                variant="primary"
                button
                @click="(projectDetailsSideBar = true), (project = data.item)"
              >
                <feather-icon icon="EyeIcon" size="20" />
              </b-avatar>
            </b-col>
          </b-row>
        </template>

        <template #cell(note)="data">
          <b-form-rating
            style="background-color: transparent"
            disabled
            :value="data.item.note"
            no-border
            class="text-warning"
          ></b-form-rating>
        </template>
      </b-table>
      <b-modal
        hide-header-close
        v-model="showDeletedConfModal"
        title="Attention !"
        header-bg-variant="danger"
        ok-title="Oui, supprimer"
        ok-variant="danger"
        cancel-title="Non, annuler"
        cancel-variant="outline-primary"
        @ok="deleteThisProject(project_id)"
        :busy="deletedLoading"
      >
        <p>
          Voules-vous vraiment su^pprimer ce projet ? <br />
          cette action est irréverssible.
        </p>
      </b-modal>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage par page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="this.filteredItems.length"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-sidebar
      backdrop
      shadow
      right
      width="850px"
      v-model="projectDetailsSideBar"
      id="sidebar-footer"
      aria-label="Sidebar with custom footer"
      no-header
    >
      <!-- <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
          <b-button size="sm" @click="hide">Fermer</b-button>
        </div>
      </template> -->
      <div class="px-3 py-2">
        <b-card>
          <template #header>
            <h2>Projet :</h2>
          </template>
          <validation-observer>
            <b-form>
              <b-row>
                <b-col cols="12" md="12">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Nom du projet'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.name"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Date de début'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.start_date"
                  />
                </b-col>
                <b-col cols="12" md="6">
                  <ky-input
                    :disabled="true"
                    :placeholder="'Date de fin'"
                    :type="'text'"
                    :rules="'required'"
                    v-model="project.end_date"
                  />
                </b-col>
                <b-col cols="12" md="12">
                  <validation-provider>
                    <b-form-group>
                      <b-form-textarea readonly v-model="project.description" />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <div class="ml-1 mb-2">
                  Responsable du projet :
                  <strong> {{ project.master }} </strong>

                  <div>
                    Note :
                    <b-form-rating
                      variant="warning"
                      id="rating-md-no-border"
                      inline
                      v-model="project.note"
                      no-border
                      readonly
                    >
                    </b-form-rating>
                    <b-badge v-if="project.is_changed == 1">Modifiée</b-badge>
                  </div>
                </div>
                <b-col v-if="project.is_finished == 1" cols="12">
                  <b-card title="Commentaires">
                    <app-timeline>
                      <app-timeline-item
                        v-for="comment in comments"
                        :key="comment.id"
                        :title="comment.message"
                        :subtitle="comment.author_id"
                        :time="_time(comment.created_at)"
                        variant="success"
                      />
                    </app-timeline>
                  </b-card>
                </b-col>
                <b-col v-if="project.is_finished == 1" cols="12">
                  <validation-observer ref="commentForm">
                    <b-form @submit.prevent="postComment">
                      <b-card border-variant="black">
                        <validation-provider>
                          <label for="rating-inline">Avis : </label>
                          <b-form-rating
                            variant="warning"
                            id="rating-md-no-border"
                            inline
                            v-model="data.note"
                            no-border
                          >
                          </b-form-rating>
                        </validation-provider>
                        <validation-provider>
                          <b-form-group label="Commentaire">
                            <b-form-textarea
                              required
                              v-model="data.comment"
                              placeholder="Laissez-nous un commentaire"
                            />
                          </b-form-group>
                        </validation-provider>
                        <div class="text-right">
                          <b-button type="submit" variant="primary"
                            >Envoyer</b-button
                          >
                        </div>
                      </b-card>
                    </b-form>
                  </validation-observer>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
        <b-card title="Progression du projet">
          <div>
            <b-progress
              :value="progress"
              :max="100"
              show-progress
              animated
            ></b-progress>
          </div>
        </b-card>
        <b-card>
          <b-row class="d-flex justify-content-between">
            <b-col cols="auto"> Tâches du projet </b-col>
            <b-col cols="auto">
              <B-button @click="addTaskModal = true">Nouvelle tâche</B-button>
            </b-col>
          </b-row>
          <hr />
          <b-table
            :busy="taskLoading"
            @filtered="onFilteredTasks"
            :current-page="currentPage"
            :per-page="perPage"
            small
            :fields="fields"
            :items="customerProjectTasks"
            responsive="sm"
          >
            <template #cell(start_date)="data">
              {{ getDate(data.item.start_date) }}
            </template>
            <template #cell(end_date)="data">
              {{ getDate(data.item.end_date) }}
            </template>
            <template #cell(rating)="data">
              <b-form-rating
                v-if="data.item.comment"
                variant="warning"
                id="rating-md-no-border"
                inline
                v-model="data.item.comment.note"
                no-border
                readonly
              >
              </b-form-rating>
            </template>
            <template #cell(actions)="data">
              <b-avatar
                v-if="data.item.is_closed == false"
                variant="success"
                button
                @click="wantToEditTask(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-avatar>

              <b-avatar
                :id="'rating-popover-' + data.item.id"
                v-if="data.item.is_closed == true"
                variant="warning"
                button
                @click="getTaskToRate(data.item)"
              >
                <feather-icon icon="StarIcon" />
              </b-avatar>
              <b-popover
                v-if="data.item.is_closed == true"
                triggers="focus"
                :target="'rating-popover-' + data.item.id"
                placement="top"
              >
                <template #title>Avis</template>
                Laissez-nous votre avis concernant l'exécution de cette tâche.
                :)
                <validation-observer ref="commentForm">
                  <b-form @submit.prevent="commentTask">
                    <validation-provider>
                      <label for="rating-inline">Note : </label>
                      <b-form-rating
                        variant="warning"
                        id="rating-md-no-border"
                        inline
                        v-model="comment.note"
                        no-border
                      >
                      </b-form-rating>
                    </validation-provider>
                    <validation-provider>
                      <b-form-group label="Commentaire">
                        <b-form-textarea
                          required
                          v-model="comment.message"
                          placeholder="Laissez-nous un commentaire"
                        />
                      </b-form-group>
                    </validation-provider>
                    <div class="text-right">
                      <b-button type="submit" variant="primary"
                        >Envoyer</b-button
                      >
                    </div>
                  </b-form>
                </validation-observer>
              </b-popover>
            </template>
          </b-table>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="this.filteredItems.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card>
        <b-modal
          no-close-on-backdrop
          centered
          hide-backdrop
          hide-footer
          hide-header-close
          title="Nouvelle tâche"
          size="lg"
          v-model="addTaskModal"
        >
          <validation-observer ref="createTaskForm">
            <b-form>
              <validation-provider
                rules="required"
                #default="{ errors }"
                name="Nom de la tâche"
              >
                <b-form-group label="Nom de la tâche">
                  <b-form-input
                    placeholder="nom de la tâche"
                    v-model="task.name"
                  />
                  <small v-if="errors" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
              <b-row>
                <b-col>
                  <validation-provider>
                    <b-form-group label="Date de début">
                      <b-form-input
                        type="date"
                        placeholder="date de début"
                        v-model="task.start_date"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider>
                    <b-form-group label="Date de fin">
                      <b-form-input
                        type="date"
                        placeholder="date de fin"
                        v-model="task.end_date"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <validation-provider>
                <b-form-group label="Description">
                  <b-form-textarea
                    placeholder="description..."
                    v-model="task.description"
                  />
                </b-form-group>
              </validation-provider>
              <div class="text-center">
                <b-button
                  variant="outline-danger"
                  class="mr-2"
                  @click="closeTaskModal()"
                  >ANNULER
                </b-button>
                <b-button
                  variant="primary"
                  @click="createTask"
                  :disabled="addTaskLoading"
                >
                  AJOUTER
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-modal>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import { BProgress } from "bootstrap-vue";
import ProjectRequest from "./ProjectRequest.vue";
import moment from "moment";

import { mapActions, mapState } from "vuex";

export default {
  components: {
    BProgress,
    ProjectRequest,
    KyInput,
    AppTimeline,
    AppTimelineItem,
    ToastificationContent,
  },
  data() {
    return {
      showDeletedConfModal: false,
      project_id: null,
      taskLoading: false,
      wantEdit: false,
      addTaskLoading: false,
      task: {
        id: null,
        project_id: null,
        customer_id: JSON.parse(localStorage.getItem("customerInfo")).id,
        enterprise_id: null,
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        priority: 0,
      },

      task_selected: {},
      fields: [
        { key: "name", label: "Nom" },
        { key: "start_date", label: "Début" },
        { key: "end_date", label: "Fin" },
        { key: "rating", label: "Note" },
        { key: "actions", label: "Actions" },
      ],

      addTaskModal: false,
      q: {
        customerId: JSON.parse(localStorage.getItem("customerInfo")).id,
        search: "waiting",
      },
      choice: "waiting",
      progress: 0,
      options: [
        { text: "En attente de validation", value: "waiting" },
        { text: "En cours", value: "in_progress" },
        { text: "Réjétés", value: "rejected" },
      ],

      data: {
        note: 0,
        comment: "",
      },

      comment: {
        note: 0,
        message: "",
        task_id: null,
        customer_id: null,
      },

      projectDetailsSideBar: false,

      rows: 0,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterTable: "",
      project: {},
      customerId: JSON.parse(localStorage.getItem("customerInfo")).id,

      projectsItems: [
        { key: "name", label: "Projet", sortable: true },
        { key: "start_date", label: "Date de début", sortable: true },
        { key: "end_date", label: "Date de fin", sortable: true },
        { key: "master", label: "Responsable", sortable: true },
        { key: "note", label: "Note", sortable: true },
        { key: "action", label: "Action" },
      ],

      deletedLoading: false,
    };
  },
  computed: {
    sortOptions() {
      return this.projectsItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("auth", ["userInfo"]),
    ...mapState("projects", ["comments"]),
    ...mapState("customer", ["projects", "customerInfo"]),
    ...mapState("tasks", ["customerProjectTasks"]),

    filteredItems() {
      return (
        this.projects?.filter((item) => {
          return item?.name
            .toLowerCase()
            .includes(this.filterTable.toLowerCase());
        }) || this.projects
      );
    },
  },
  mounted() {
    this.fetchCustomerProjects(this.q);
  },

  watch: {
    choice(val) {
      this.q.search = val;
      this.fetchCustomerProjects(this.q);
    },

    project(val) {
      (this.project_id = val.id), (this.taskLoading = true);
      this.data.note = val.note;
      this.getProjectComments(val.id);
      this.getCustomerProjectTasks(val.id).then((res) => {
        this.taskLoading = false;
      });

      this.getProjectProgress(val.id).then((res) => {
        this.progress = res.data;
      });
    },
  },

  methods: {
    deleteThisProject(id) {
      this.deletedLoading = true;
      this.deletePendingProject(id).then(() => {
        this.fetchCustomerProjects(this.q);
        this.showDeletedConfModal = false;
        this.deletedLoading = false;
      });
    },

    closeTaskModal() {
      this.addTaskModal = false;
      this.task.name = "";
      this.task.start_date = "";
      this.task.end_date = "";
      this.task.description = "";
    },

    _time(time) {
      return moment(time).locale("fr").fromNow();
    },

    wantToEditTask(task) {
      this.addTaskModal = true;
      this.task = task;
      this.wantEdit = true;
    },

    getDate(date) {
      return moment(date).locale("fr").format("Do MMMM YYYY");
    },

    onFiltered(items) {
      this.totalRows = items.length;
      this.currentPage = 1;
    },
    onFilteredTasks(items) {
      this.totalRows = items.length;
      this.currentPage = 1;
    },

    rowClass(item, type) {
      const is_accepted = "table-success text-success";
      const is_rejected = "table-danger text-danger";
      if (!item || type !== "row") {
        return;
      }
      if (item.state == 1) {
        return is_accepted;
      }
      if (item.state == 2) {
        return is_rejected;
      }
    },

    postComment() {
      this.$store
        .dispatch("projects/makeComment", {
          project_id: this.project.id,
          customer_id: JSON.parse(localStorage.getItem("customerInfo")).id,
          message: this.data.comment,
          note: this.data.note,
        })
        .then((res) => {
          this.fetchCustomerProjects(this.q);
          this.getProjectComments(this.project.id);
          this.data.comment = "";
          this.$nextTick().then((res) => {
            this.$refs.commentForm.reset();
          });
        });
    },

    getTaskToRate(task) {
      this.comment = {
        note: 0,
        message: "",
        task_id: null,
        customer_id: null,
      };
      if (task.comment) {
        this.comment = task.comment;
      }
      this.task_selected = Object.assign({}, task);
    },

    async commentTask() {
      const isValid = await this.$refs.commentForm.validate();
      if (!isValid) return;
      this.comment.task_id = this.task_selected.id;
      this.comment.customer_id = JSON.parse(
        localStorage.getItem("customerInfo")
      ).id;
      await this.rateProject(this.$formData(this.comment))
        .then(() => {
          this.task_selected = {};
          this.taskLoading = true;
          this.getCustomerProjectTasks(this.project_id).then((res) => {
            this.taskLoading = false;
          });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Note enregistrée !",
              variant: "success",
            },
          });
        })
        .catch((err) => {});
    },

    async createTask() {
      this.addTaskLoading = true;
      const isValid = await this.$refs.createTaskForm.validate();
      if (!isValid) return;
      this.task.project_id = this.project.id;
      if (this.wantEdit == false) {
        this.createTaskOnlyInCustomerSpacerArea(this.$formData(this.task))
          .then((res) => {
            this.addTaskLoading = false;
            this.addTaskModal = false;
            this.taskLoading = true;
            this.getCustomerProjectTasks(this.project_id).then((res) => {
              this.taskLoading = false;
            });
          })
          .catch((err) => {
            this.addTaskLoading = false;
          });
      } else {
        this.editTask(this.$formData(this.task))
          .then((res) => {
            this.addTaskLoading = false;
            this.addTaskModal = false;
          })
          .catch((err) => {
            this.addTaskLoading = false;
          });
      }
    },

    ...mapActions("enterprise", ["getEmployees"]),
    ...mapActions("projects", [
      "makeComment",
      "getProjectComments",
      "getProjectProgress",
      "deletePendingProject",
    ]),
    ...mapActions("customer", ["fetchCustomerProjects"]),
    ...mapActions("tasks", [
      "createTaskOnlyInCustomerSpacerArea",
      "getCustomerProjectTasks",
      "editTask",
      "rateProject",
    ]),
  },
};
</script>
